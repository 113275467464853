
    import {defineComponent, ref, onMounted, reactive, toRefs, UnwrapRef} from 'vue';
    import {
        getTenList,
        getProblemList,
        getComplainList,
        saveComment,
        deleteSelectItem,
        saveProblemComment,
        deleteSelectProblemItem,
        saveComplainComment,
        deleteSelectComplainItem,
        getGd,
        getGd1,
    } from '@/network/customer';
    import {ColumnProps} from 'ant-design-vue/es/table/interface';
    import {message} from "ant-design-vue";
    import {CheckOutlined, EditOutlined} from '@ant-design/icons-vue';
    import {cloneDeep} from 'lodash-es';
    import moment from 'moment';
    import baseUrl from '@/util/api';

    type Key = ColumnProps['key'];

    interface FileInfo {
        file: FileItem;
        fileList: FileItem[];
    }

    interface FileItem {
        uid: string;
        name?: string;
        status?: string;
        response?: string;
        url?: string;
    }

    interface WhereItem {
        page: number;
        size: number;
        shipper: string;
        tracker: string;
        site: string;
        product: string;
        comment: boolean;
        time: any;
    }

    interface WhereItem1 {
        page: number;
        size: number;
        shipper: string;
        sn: string;
        site: string;
        product: string;
        comment: undefined | number;
        time: any;
    }

    interface WhereItem2 {
        page: number;
        size: number;
        site: string;
        country: string;
        reason: number | undefined;
        product: string;
        time: any;
    }

    interface DataItem {
        id: number;
        comment: string;
    }

    export default defineComponent({
        name: "Cimport",
        components: {
            CheckOutlined,
            EditOutlined,
        },
        setup() {
            const state = reactive<{
                selectedRowKeys: Key[];
            }>({
                selectedRowKeys: [], // Check here to configure the default column
            });
            const columns = [
                {title: 'ID', width: 80, dataIndex: 'id', key: 'id', fixed: 'left'},
                {
                    title: '登记日期',
                    width: 100,
                    dataIndex: 'register_time',
                    key: 'register_time',
                    fixed: 'left',
                    customRender: (text, record, index) => {
                        return moment.unix(text.text).format('YYYY-MM-DD');
                    },
                },
                {title: '订单编号', dataIndex: 'sn', key: 'sn', width: 200},
                {
                    title: '物流商', dataIndex: 'shipper', key: 'shipper', width: 100
                },
                {
                    title: '跟踪号', dataIndex: 'tracker', key: 'tracker', width: 200
                },
                {title: '站名', dataIndex: 'site', key: 'site', width: 100},
                {title: '邮箱', dataIndex: 'mail', key: 'mail', width: 150},
                {
                    title: '备注（是否接受补偿）', dataIndex: 'comment', key: 'comment', width: 100,
                    slots: {customRender: 'comment'}
                },
                {
                    title: '产品名称', dataIndex: 'product', key: 'product', width: 100
                },
                {
                    title: '处理人', dataIndex: 'uname', key: 'uname', width: 100
                },
                {
                    title: '操作',
                    key: 'operation',
                    fixed: 'right',
                    width: 120,
                    slots: {customRender: 'action'},
                },
            ];
            const where = ref<WhereItem>({
                page: 1,
                size: 100,
                shipper: '',
                tracker: '',
                site: '',
                product: '',
                comment: false,
                time: [],
            });
            const fileList = ref([]);
            let range = ref<any>({
                'startTime': '',
                'endTime': '',
            });
            //const timeVal = ref<any>([]);
            const dateFormat = "YYYY-MM-DD";
            const handleTimeChange = (value, dateString) => {
                console.log('Formatted Selected Time: ', dateString);
                range.value.startTime = dateString[0] + "0:0:0";
                range.value.endTime = dateString[1] + "23:59:59";
                where.value.time = (dateString[0] && dateString[1]) ? [moment(dateString[0], dateFormat), moment(dateString[1], dateFormat)] : [];
            };
            const handleChange = (info: FileInfo) => {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status == 'done') {
                    message.success('上传成功');
                    where.value.page = 1;
                    list(where.value);
                } else if (info.file.status === 'error') {
                    message.error('上传失败');
                }
            };
            const data = ref<any[]>([]);
            const loading = ref(false);
            const pagination = reactive({
                total: 0,
                pageSize: 100,
            });
            const list = (where) => {
                loading.value = true;
                getTenList({...where}).then((response) => {
                    loading.value = false;
                    if (response.status == 'done') {
                        data.value = response.list;
                        pagination.total = response.total;
                        //message.success(`${response.msg} `);
                    } else {
                        message.error(`${response.msg} `);
                        data1.value = response.list;
                    }
                })
            };
            const handleTableChange = (pagination, filters, sorter) => {
                state.selectedRowKeys = [];
                where.value.page = pagination.current;
                list(where.value);
            };
            const ids = ref<any>([]);
            const onSelectChange = (selectedRowKeys: Key[]) => {
                console.log('selectedRowKeys changed: ', selectedRowKeys);
                ids.value = [];
                state.selectedRowKeys = selectedRowKeys;
                selectedRowKeys.forEach(function (item: any) {
                    ids.value.push(data.value[item].id);
                })
            };
            const editableData: UnwrapRef<Record<string, DataItem>> = reactive({});
            const edit = (id: number) => {
                editableData[id] = cloneDeep(data.value.filter(item => id === item.id)[0]);
            };
            const save = (id: number) => {
                Object.assign(data.value.filter(item => id === item.id)[0], editableData[id]);
                saveComment({'id': id, 'comment': editableData[id].comment}).then((response) => {
                    if (response.status == 'done') {
                        message.success(`${response.msg} `);
                    } else {
                        message.error(`${response.msg} `);
                    }
                });
                delete editableData[id];
            };
            const search = () => {
                where.value.page = 1;
                list(where.value);
            };
            const deleteItem = () => {
                deleteSelectItem({'ids': ids.value}).then((response) => {
                    if (response.status == 'done') {
                        message.success(`${response.msg} `);
                        state.selectedRowKeys = [];
                        list(where.value);
                    } else {
                        message.error(`${response.msg} `);
                    }
                })
            }
            const state1 = reactive<{
                selectedRowKeys1: Key[];
            }>({
                selectedRowKeys1: [], // Check here to configure the default column
            });
            const columns1 = [
                {title: 'ID', width: 80, dataIndex: 'id', key: 'id', fixed: 'left'},
                {
                    title: '日期',
                    width: 100,
                    dataIndex: 'date',
                    key: 'date',
                    fixed: 'left',
                    customRender: (text, record, index) => {
                        return moment.unix(text.text).format('YYYY-MM-DD');
                    },
                },
                {
                    title: '物流商', dataIndex: 'shipper', key: 'shipper', width: 100
                },
                {title: '物流单号', dataIndex: 'sn', key: 'sn', width: 200},
                {
                    title: '转单号', dataIndex: 'return_sn', key: 'return_sn', width: 200
                },
                {title: '付款方式', dataIndex: 'pay', key: 'pay', width: 100},
                {title: '站名', dataIndex: 'site', key: 'site', width: 100},
                {title: '买家邮箱', dataIndex: 'mail', key: 'mail', width: 150},
                {title: '问题', dataIndex: 'problem', key: 'problem', width: 150},
                {title: '截止日期', dataIndex: 'end_time', key: 'end_time', width: 100},
                {
                    title: '备注（如已重派已签收）', dataIndex: 'comment', key: 'comment', width: 150,
                    slots: {customRender: 'comment'}
                },
                {
                    title: '产品名', dataIndex: 'product', key: 'product', width: 100
                },
                {
                    title: '处理人', dataIndex: 'uname', key: 'uname', width: 100
                },
                {
                    title: '操作',
                    key: 'operation',
                    fixed: 'right',
                    width: 120,
                    slots: {customRender: 'action'},
                },
            ];
            const where1 = ref<WhereItem1>({
                page: 1,
                size: 100,
                shipper: '',
                sn: '',
                site: '',
                product: '',
                comment: undefined,
                time: [],
            });
            const fileList1 = ref([]);
            let range1 = ref<any>({
                'startTime': '',
                'endTime': '',
            });
            //const timeVal = ref<any>([]);
            const handleTimeChange1 = (value, dateString) => {
                console.log('Formatted Selected Time: ', dateString);
                range1.value.startTime = dateString[0] + "0:0:0";
                range1.value.endTime = dateString[1] + "23:59:59";
                where1.value.time = (dateString[0] && dateString[1]) ? [moment(dateString[0], dateFormat), moment(dateString[1], dateFormat)] : [];
            };
            const handleChange1 = (info: FileInfo) => {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status == 'done') {
                    message.success('上传成功');
                    where1.value.page = 1;
                    list1(where1.value);
                } else if (info.file.status === 'error') {
                    message.error('上传失败');
                }
            };
            const data1 = ref<any[]>([]);
            const loading1 = ref(false);
            const pagination1 = reactive({
                total: 0,
                pageSize: 100,
            });
            const list1 = (where) => {
                loading1.value = true;
                getProblemList({...where}).then((response) => {
                    loading1.value = false;
                    if (response.status == 'done') {
                        data1.value = response.list;
                        pagination1.total = response.total;
                        //message.success(`${response.msg} `);
                    } else {
                        message.error(`${response.msg} `);
                        data1.value = response.list;
                    }
                })
            };
            const handleTableChange1 = (pagination, filters, sorter) => {
                state1.selectedRowKeys1 = [];
                where1.value.page = pagination.current;
                list1(where1.value);
            };
            const ids1 = ref<any>([]);
            const onSelectChange1 = (selectedRowKeys: Key[]) => {
                console.log('selectedRowKeys changed: ', selectedRowKeys);
                ids1.value = [];
                state1.selectedRowKeys1 = selectedRowKeys;
                selectedRowKeys.forEach(function (item: any) {
                    ids1.value.push(data1.value[item].id);
                })
            };
            const editableData1: UnwrapRef<Record<string, DataItem>> = reactive({});
            const edit1 = (id: number) => {
                editableData1[id] = cloneDeep(data1.value.filter(item => id === item.id)[0]);
            };
            const save1 = (id: number) => {
                Object.assign(data1.value.filter(item => id === item.id)[0], editableData1[id]);
                saveProblemComment({'id': id, 'comment': editableData1[id].comment}).then((response) => {
                    if (response.status == 'done') {
                        message.success(`${response.msg} `);
                    } else {
                        message.error(`${response.msg} `);
                    }
                });
                delete editableData1[id];
            };
            const search1 = () => {
                where1.value.page = 1;
                list1(where1.value);
            };
            const deleteItem1 = () => {
                deleteSelectProblemItem({'ids': ids1.value}).then((response) => {
                    if (response.status == 'done') {
                        message.success(`${response.msg} `);
                        state1.selectedRowKeys1 = [];
                        list1(where1.value);
                    } else {
                        message.error(`${response.msg} `);
                    }
                })
            }
            const state2 = reactive<{
                selectedRowKeys2: Key[];
            }>({
                selectedRowKeys2: [], // Check here to configure the default column
            });
            const columns2 = [
                {title: 'ID', width: 80, dataIndex: 'id', key: 'id', fixed: 'left'},
                {
                    title: '登记日期',
                    width: 150,
                    dataIndex: 'register_time',
                    key: 'register_time',
                    fixed: 'left',
                    customRender: (text, record, index) => {
                        return moment.unix(text.text).format('YYYY-MM-DD');
                    },
                },
                {
                    title: '事件号', dataIndex: 'event', key: 'event', width: 100
                },
                {
                    title: '站名', dataIndex: 'site', key: 'site', width: 150
                },
                {title: '国家', dataIndex: 'country', key: 'country', width: 100},
                {title: '原因', dataIndex: 'reason', key: 'reason', width: 150, slots: {customRender: 'reason'},},
                {title: '金额', dataIndex: 'price', key: 'price', width: 100},
                {title: '币种', dataIndex: 'money_type', key: 'money_type', width: 100},
                {title: '买家留言', dataIndex: 'comment', key: 'comment', width: 150, ellipsis: true},
                {
                    title: '翻译', dataIndex: 'translate', key: 'translate', width: 150, ellipsis: true
                },
                {
                    title: '发货时间',
                    dataIndex: 'deliver_time',
                    key: 'deliver_time',
                    width: 120,
                    customRender: (text, record, index) => {
                        if (text.text != 0) {
                            return moment.unix(text.text).format('YYYY-MM-DD');
                        } else {
                            return '没发货';
                        }

                    },
                },
                {
                    title: '签收入库时间',
                    dataIndex: 'stock_time',
                    key: 'stock_time',
                    width: 120,
                    customRender: (text, record, index) => {
                        if (text.text != 0) {
                            return moment.unix(text.text).format('YYYY-MM-DD');
                        } else {
                            return '没签收入库';
                        }
                    },
                },
                {
                    title: '开立日期',
                    dataIndex: 'complain_time',
                    key: 'complain_time',
                    width: 120,
                    customRender: (text, record, index) => {
                        if (text.text != 0) {
                            return moment.unix(text.text).format('YYYY-MM-DD');
                        } else {
                            return '没投诉';
                        }
                    },
                },
                {
                    title: 'paypalID', dataIndex: 'paypal', key: 'paypal', width: 100
                },
                {
                    title: '付款日期',
                    dataIndex: 'pay_time',
                    key: 'pay_time',
                    width: 120,
                    customRender: (text, record, index) => {
                        return moment.unix(text.text).format('YYYY-MM-DD');
                    },
                },
                {
                    title: '订单编号', dataIndex: 'sn', key: 'sn', width: 100
                },
                {
                    title: '邮箱反馈（最新）', dataIndex: 'feedback', key: 'feedback', width: 200, ellipsis: true
                },
                {
                    title: '出单后采购时效', dataIndex: 'get_range', key: 'get_range', width: 100
                },
                {
                    title: '采购后到货时效', dataIndex: 'arrival_range', key: 'arrival_range', width: 100
                },
                {
                    title: '前两列之和', dataIndex: 'sum_range', key: 'sum_range', width: 100
                },
                {
                    title: '发货时效（发货时间-签收入库时间）', dataIndex: 'send_range', key: 'send_range', width: 100
                },
                {
                    title: '物流时效（天）', dataIndex: 'ship_range', key: 'ship_range', width: 100
                },
                {
                    title: '物流公司', dataIndex: 'ship', key: 'ship', width: 100
                },
                {
                    title: '货运单号', dataIndex: 'ship_sn', key: 'ship_sn', width: 100
                },
                {
                    title: '订单商品名称', dataIndex: 'product', key: 'product', width: 150, ellipsis: true
                }
            ];
            const where2 = ref<WhereItem2>({
                page: 1,
                size: 100,
                site: '',
                country: '',
                reason: undefined,
                product: '',
                time: [],
            });
            const fileList2 = ref([]);
            let range2 = ref<any>({
                'startTime': '',
                'endTime': '',
            });
            //const timeVal = ref<any>([]);
            const handleTimeChange2 = (value, dateString) => {
                console.log('Formatted Selected Time: ', dateString);
                range2.value.startTime = dateString[0] + "0:0:0";
                range2.value.endTime = dateString[1] + "23:59:59";
                where2.value.time = (dateString[0] && dateString[1]) ? [moment(dateString[0], dateFormat), moment(dateString[1], dateFormat)] : [];
            };
            const handleChange2 = (info: FileInfo) => {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status == 'done') {
                    message.success('上传成功');
                    where2.value.page = 1;
                    list2(where2.value);
                } else if (info.file.status === 'error') {
                    message.error('上传失败');
                }
            };
            const data2 = ref<any[]>([]);
            const loading2 = ref(false);
            const pagination2 = reactive({
                total: 0,
                pageSize: 100,
            });
            const list2 = (where) => {
                loading2.value = true;
                getComplainList({...where}).then((response) => {
                    loading2.value = false;
                    if (response.status == 'done') {
                        data2.value = response.list;
                        pagination2.total = response.total;
                        //message.success(`${response.msg} `);
                    } else {
                        data2.value = response.list;
                        message.error(`${response.msg} `);
                    }
                })
            };
            const handleTableChange2 = (pagination, filters, sorter) => {
                state2.selectedRowKeys2 = [];
                where2.value.page = pagination.current;
                list2(where2.value);
            };
            const ids2 = ref<any>([]);
            const onSelectChange2 = (selectedRowKeys: Key[]) => {
                console.log('selectedRowKeys changed: ', selectedRowKeys);
                ids2.value = [];
                state2.selectedRowKeys2 = selectedRowKeys;
                selectedRowKeys.forEach(function (item: any) {
                    ids2.value.push(data2.value[item].id);
                })
            };
            const editableData2: UnwrapRef<Record<string, DataItem>> = reactive({});
            const edit2 = (id: number) => {
                editableData2[id] = cloneDeep(data2.value.filter(item => id === item.id)[0]);
            };
            const save2 = (id: number) => {
                Object.assign(data2.value.filter(item => id === item.id)[0], editableData2[id]);
                saveComplainComment({'id': id, 'comment': editableData2[id].comment}).then((response) => {
                    if (response.status == 'done') {
                        message.success(`${response.msg} `);
                    } else {
                        message.error(`${response.msg} `);
                    }
                });
                delete editableData2[id];
            };
            const search2 = () => {
                where2.value.page = 1;
                list2(where2.value);
            };
            const deleteItem2 = () => {
                deleteSelectComplainItem({'ids': ids2.value}).then((response) => {
                    if (response.status == 'done') {
                        message.success(`${response.msg} `);
                        state2.selectedRowKeys2 = [];
                        list2(where2.value);
                    } else {
                        message.error(`${response.msg} `);
                    }
                })
            }
            const uid = window.localStorage.getItem('uid');
            const get = (id) => {
                getGd({'id': id, 'uid': uid}).then((response) => {
                    if (response.status == 'done') {
                        message.success(`${response.msg} `);
                        list(where.value);
                    } else {
                        message.error(`${response.msg} `);
                    }
                });
            }
            const get1 = (id) => {
                getGd1({'id': id, 'uid': uid}).then((response) => {
                    if (response.status == 'done') {
                        message.success(`${response.msg} `);
                        list1(where1.value);
                    } else {
                        message.error(`${response.msg} `);
                    }
                });
            }
            onMounted(() => {
                list(where.value);
                list1(where1.value);
                list2(where2.value);
            })
            return {
                pagination,
                pagination1,
                pagination2,
                handleTableChange,
                handleTableChange1,
                handleTableChange2,
                handleChange,
                handleChange1,
                handleChange2,
                fileList,
                fileList1,
                fileList2,
                loading,
                loading1,
                loading2,
                data,
                data1,
                data2,
                baseUrl: baseUrl.apiUrl,
                handleTimeChange,
                handleTimeChange1,
                handleTimeChange2,
                //timeVal,
                where,
                where1,
                where2,
                headers: {'Authorization': window.localStorage.getItem('token')},
                columns,
                columns1,
                columns2,
                onSelectChange,
                onSelectChange1,
                onSelectChange2,
                ...toRefs(state),
                ...toRefs(state1),
                ...toRefs(state2),
                edit,
                save,
                editableData,
                search,
                deleteItem,
                edit1,
                save1,
                editableData1,
                search1,
                deleteItem1,
                edit2,
                save2,
                editableData2,
                search2,
                deleteItem2,
                get,
                get1,
                uid,
            }
        }
    })
