import http from './http'

export function submit(data) {
    return http.post('/index/customer/submit', data)
}
export function isSame(data) {
    return http.post('/index/customer/isSame', data)
}
export function getUser(data) {
    return http.post('/index/customer/getUser', data)
}
export function getList(data) {
    return http.post('/index/customer/getList', data)
}
export function getAllList(data) {
    return http.post('/index/customer/getAllList', data)
}
export function close(data) {
    return http.post('/index/customer/close', data)
}
export function getTenList(data) {
    return http.post('/index/customer/getTenList', data)
}
export function getProblemList(data) {
    return http.post('/index/customer/getProblemList', data)
}
export function getComplainList(data) {
    return http.post('/index/customer/getComplainList', data)
}
export function saveComment(data) {
    return http.post('/index/customer/saveComment', data)
}
export function deleteSelectItem(data) {
    return http.post('/index/customer/deleteSelectItem', data)
}
export function saveProblemComment(data) {
    return http.post('/index/customer/saveProblemComment', data)
}
export function deleteSelectProblemItem(data) {
    return http.post('/index/customer/deleteSelectProblemItem', data)
}
export function saveComplainComment(data) {
    return http.post('/index/customer/saveComplainComment', data)
}
export function deleteSelectComplainItem(data) {
    return http.post('/index/customer/deleteSelectComplainItem', data)
}
export function getGd(data) {
    return http.post('/index/customer/getGd', data)
}
export function getGd1(data) {
    return http.post('/index/customer/getGd1', data)
}export function getSearch(data) {
    return http.post('/index/customer/getSearch', data)
}
